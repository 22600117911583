<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="selectionList"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                        <vs-button class="ml-4 mt-2" type="border" color="warning" @click="leaveNews">Quay lại
                        </vs-button>
                    </div>
                </div>
            </div>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../ui-elements/Pagination.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererImgSelection from "./cell-renderer/CellRendererImgSelection";
// import moment from "moment";

export default {
    components: {
        AgGridVue,

        // Cell Renderer
        Pagination,
        CellRendererActions,
        CellRendererImgSelection
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Người chọn',
                    field: 'avatar',
                    cellRendererFramework: 'CellRendererImgSelection',
                    width: 250
                },
                {
                    headerName: 'Lựa chọn',
                    field: 'name',
                    width: 120
                }
            ],
            selectionList: [],
            components: {
                CellRendererActions,
                CellRendererImgSelection
            },
        }

    },
    created() {
        this.initScreen();
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.deleteBranchStatus'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('DELETE_BRANCH_STATUS', false);
            }
        },
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        groupByEmployee(myArray){
            let groups = {};
            for (let i = 0; i < myArray.length; i++) {
                let groupName = myArray[i].count;
                if (!groups[groupName]) {
                    groups[groupName] = [];
                }
                groups[groupName].push(myArray[i]);
            }
            return groups;
        },
        initScreen() {
            this.$vs.loading();
            this.$crm.get('/posts/option/find-all?postId=' + this.$route.query.postId).then((response) => {
                this.selectionList = response.data;
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        leaveNews() {
            this.$router.push(`/user/manage-News`).catch(() => {
            })
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
