<template>
    <div class="show-img">
        <div v-for="(item,index) in this.params.data.employeeDetailResponseDTOS" :key="index">
            <vx-tooltip class="position-icon leave" :text="item.fullName"
                        position="top">
                <img :src="item.avatar ? item.avatar : item.imageStore[0].url" class=" w-full img-checkin"
                     @click="onclickImg(item)"/>
            </vx-tooltip>
            <vs-popup :title="isTitle" :active.sync="activePopUpImg">
                <img :src="srcAvatar" width="567">
            </vs-popup>
        </div>
    </div>
</template>
<script>

export default {
    name: 'CellRendererImages',
    data() {
        return {
            activePopUpImg: false,
            isTitle: "Hình ảnh phóng to",
            srcAvatar: null
        }
    },
    methods: {
        onclickImg(item) {
            this.srcAvatar = item.avatar ? item.avatar : item.imageStore[0].url;
            this.activePopUpImg = true;
        },
    },
}
</script>
<style scoped>

.show-img .img-checkin {
    height: 50px;
    padding: 5px;
    width: 50px !important;
    border-radius: 50%;
    overflow: hidden;
    display: block;
}

.show-img {
    position: relative;
}

</style>
